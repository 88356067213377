<div class="container">
    <div class="row">
        <div class="col">
            <h1>Amanda J.</h1>
            <h1>Kendal-Brown</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h3 style="text-align: right;">About Me</h3>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem qui laboriosam saepe quis aut molestiae voluptatibus suscipit minima maiores, et iure modi illo nulla cupiditate sit, nihil laborum voluptas cum?
            </p>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-sm-4 col-md-4">
           <app-world-ranking></app-world-ranking>
           <app-follow-me></app-follow-me>
        </div>
    </div>
</div>


import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-world-ranking',
  templateUrl: './world-ranking.component.html',
  styleUrls: ['./world-ranking.component.scss']
})
export class WorldRankingComponent implements OnInit {

  worldArcheryData;
  countryName;
  rankings;
  currentRanking;
  bestRanking;
  image;

  constructor(private http: HttpClient) { }

  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  ngOnInit() {
    this.http.get<any>('https://api.worldarchery.org/v3/ATHLETEBIOGRAPHY/?Id=24437&Detailed=True').subscribe(data => {
      this.worldArcheryData = data.items[0];
      this.countryName = this.worldArcheryData.CountryName;
      this.rankings = this.worldArcheryData.WorldRankings;
      this.currentRanking = this.ordinal_suffix_of(this.rankings.Current[0].Rnk);
      this.bestRanking = this.ordinal_suffix_of(this.rankings.Best[0].Rnk);
      this.image = this.worldArcheryData.ProfilePicture.URL;
      //console.log(this.worldArcheryData);
    })
  }

}

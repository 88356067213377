import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-athlete',
  templateUrl: './athlete.component.html',
  styleUrls: ['./athlete.component.scss']
})
export class AthleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
